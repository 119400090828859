import useMiddlewareCheck from "./useMiddlewareCheck";

type CheckoutLoginParams = {
  params: {
    email?: string;
    password?: string;
    delivery: string;
  };
};

type UseCheckoutState = {
  loadingLogin: boolean | undefined;
  errorLogin: number | undefined;
  loginRetry: number;
};

export const useCheckout = () => {
  const state = useState<UseCheckoutState>(`useCheckout-error`, () => ({
    loadingLogin: undefined,
    errorLogin: 0,
    loginRetry: 0,
  }));
  const middlewareCheck = useMiddlewareCheck();

  const login = async ({ params }: CheckoutLoginParams) => {
    try {
      state.value.loadingLogin = true;
      const { data: result, error: asyncError } = await useAsyncData("checkoutLogin", () =>
        useSdk().mkmAPI.checkoutLogin({ params }),
      );
      if (result.value?.status === 200) {
        return result.value.redirectTo;
      } else {
        if (state.value.errorLogin) {
          state.value.errorLogin = result.value?.status;
          if (result.value?.retry_after) {
            state.value.errorLogin = result.value.retry_after;
          }
        }
      }
      state.value.loadingLogin = false;
    } catch (error) {
      middlewareCheck.checkIfSessionRelatedIssue(error);
    } finally {
      state.value.loadingLogin = false;
    }

    return null;
  };

  return {
    login,
    ...toRefs(state.value),
  };
};

export default useCheckout;
